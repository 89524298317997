'use client';

import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useOverlay } from '@/components/hooks/useOverlay';
import Overlay from '@/components/ui/Overlay';
import LayoutOverlay from '@/components/ui/LayoutOverlay';
import Block from '@/components/ui/Block';
import OverlayTitle from '@/components/ui/LayoutOverlay/OverlayTitle';
import ContactForm from '@/components/content/ContactForm';
import NonIdealState from '@/components/ui/NonIdealState';
import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';
import { IEmailLabels } from '@/components/content/MarketingListTool';
import useGaEvents from '@/lib/hooks/useGaEvents';
import { GaLeadEventBody } from '@/lib/types';
import { OverlayKey } from '@/lib/enums/overlayKey.enum';

interface IProps {
  form?: IMarketingListToolFilter;
  isPersonFilter?: boolean;
  formType?: 'MARKETING' | 'BACKGROUND' | 'CONTACT';
  emailLabels?: IEmailLabels;
}

const getOverlayKey = (formType: IProps['formType']): OverlayKey => {
  if (formType === 'MARKETING') {
    return OverlayKey.request_marketing_offer;
  }
  return OverlayKey.request_offer;
};

const getEventName = (formType: IProps['formType']): GaLeadEventBody['name'] => {
  if (formType === 'MARKETING') {
    return 'AskForMarketingOffer';
  }
  return 'AskForOffer';
};

const OverlayAskOffer: React.FC<IProps> = ({ form, isPersonFilter, formType, emailLabels }) => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = React.useState(false);
  const { isOverlayOpen, zIndexOfOverlay, closeOverlay } = useOverlay(getOverlayKey(formType));

  const { trackGenerateLead, trackOpenLead } = useGaEvents();
  const eventName = getEventName(formType);

  useEffect(() => {
    if (isOverlayOpen) {
      trackOpenLead({ name: eventName });
    }
  }, [isOverlayOpen]);

  const onSubmit = () => {
    trackGenerateLead({ name: eventName });
    setSubmitted(true);
  };

  return (
    <Overlay
      isOpen={isOverlayOpen}
      onClose={closeOverlay}
      layout="default"
      zIndex={zIndexOfOverlay}
      hideClose={true}
    >
      <LayoutOverlay size="default">
        <Block
          width="narrow"
          theme="lighter"
        >
          {submitted ? (
            <React.Fragment>
              <OverlayTitle onClose={closeOverlay} />
              <NonIdealState
                title={t('components.askOffer.send')}
                imageUrl="/img/success.png"
                buttons={[
                  {
                    title: t('components.askOffer.close'),
                    theme: 'primary',
                    onClick: () => {
                      setSubmitted(false);
                    },
                  },
                ]}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <OverlayTitle
                title={t('components.askOffer.title')}
                onClose={closeOverlay}
              />
              <ContactForm
                onCancel={closeOverlay}
                onSubmit={onSubmit}
                selectedForm={form}
                isPersonFilter={isPersonFilter}
                formType={formType}
                emailLabels={emailLabels}
              />
            </React.Fragment>
          )}
        </Block>
      </LayoutOverlay>
    </Overlay>
  );
};

OverlayAskOffer.displayName = 'OverlayAskOffer';

export default OverlayAskOffer;
