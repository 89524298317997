'use client';

import * as React from 'react';
import Button from '@/components/ui/Button';
import { useOverlay } from '@/components/hooks/useOverlay';
import { OverlayKey } from '@/lib/enums/overlayKey.enum';

export interface IButtonAskOfferProps {
  title: string;
}

const ButtonAskOffer: React.FunctionComponent<IButtonAskOfferProps> = (props) => {
  const { openOverlay } = useOverlay(OverlayKey.request_offer);
  return (
    <Button
      title={props.title}
      onClick={openOverlay}
      size="large"
    />
  );
};

ButtonAskOffer.displayName = 'ButtonAskOffer';

export default ButtonAskOffer;
