import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Well from '@/components/ui/Well';
import Buttons from '@/components/ui/Buttons';
import Button from '@/components/ui/Button';
import { downloadFiles } from '@/lib/helpers/downloadFiles';
import { useOverlay } from '@/components/hooks/useOverlay';
import OverlayAskOffer from '@/components/overlays/AskOffer';
import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';
import { OverlayKey } from '@/lib/enums/overlayKey.enum';
import { IEmailLabels } from '@/components/content/MarketingListTool/index';

interface IProps {
  total: number;
  form: IMarketingListToolFilter;
  isPersonFilter: boolean;
  formType?: 'MARKETING' | 'BACKGROUND' | 'CONTACT';
  emailLabels: IEmailLabels;
}

export const UnAuthorizedResults: React.FC<IProps> = ({ total, form, isPersonFilter, formType, emailLabels }) => {
  const { t } = useTranslation();
  const { openOverlay } = useOverlay(OverlayKey.request_marketing_offer);

  return (
    <div>
      <Well theme="medium">
        <p className="text-size-large bold">
          {total} {t('components.search.result')}
        </p>
        <p>{t('components.search.description')}</p>

        <Buttons>
          <Button
            title={t('components.search.sendRequest')}
            theme="primary"
            size="large"
            onClick={openOverlay}
          />
          <Button
            title={t('components.search.viewFileExample')}
            iconLeft="document-download"
            theme="default"
            size="large"
            onClick={() => downloadFiles(['/tmp/companySample.xlsx', '/tmp/personSample.xlsx'])}
          />
        </Buttons>
      </Well>
      <OverlayAskOffer
        form={form}
        isPersonFilter={isPersonFilter}
        formType={formType}
        emailLabels={emailLabels}
      />
    </div>
  );
};
