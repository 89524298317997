'use client';

import * as React from 'react';
import { useState } from 'react';
import ConsentManager from '@/components/cookies/ConsentManager';
import GoogleTagManager from '@/components/analytics/GoogleTagManager';

const ExternalScripts: React.FC = () => {
  const [managerReady, setManagerReady] = useState(false);
  return (
    <React.Fragment>
      <ConsentManager onReady={() => setManagerReady(true)} />
      {managerReady && <GoogleTagManager />}
    </React.Fragment>
  );
};

export default ExternalScripts;
