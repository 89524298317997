'use client';

import * as React from 'react';
import { DidomiSDK, OnReadyFunction } from '@didomi/react';
import useLocale from '@/lib/hooks/useLocale';

const publicApiKey = process.env.NEXT_PUBLIC_DIDOMI_API_KEY!;
const consentNoticeId = process.env.NEXT_PUBLIC_DIDOMI_CONSENT_NOTICE_ID!;

interface IProps {
  onReady?: OnReadyFunction;
}

const ConsentManager: React.FC<IProps> = (props) => {
  const locale = useLocale();

  const onReady: OnReadyFunction = (didomi) => {
    props.onReady?.(didomi);
  };

  return (
    <DidomiSDK
      apiKey={publicApiKey}
      noticeId={consentNoticeId}
      config={{
        languages: {
          enabled: [locale],
          default: locale,
        },
        theme: {
          font: 'var(--font-base), sans-serif', // Font used by the SDK
          buttons: {
            regularButtons: {
              // Learn more/disagree/disagree to all buttons.
              backgroundColor: 'rgba(0, 0, 0, 0)',
              textColor: 'var(--color-link)',
              borderColor: 'var(--color-link)',
              borderWidth: '0',
              borderRadius: 'var(--radius-default)',
            },
            highlightButtons: {
              // Agree/save/agree to all buttons.
              backgroundColor: 'var(--button-bg)',
              textColor: 'var(--button-color)',
              borderColor: 'var(--button-bg)',
              borderWidth: '0',
              borderRadius: 'var(--radius-default)',
            },
          },
        },
      }}
      onReady={onReady}
    />
  );
};

export default ConsentManager;
